import '../css/main.scss';

//import '../vendor/flickity/flickity.pkgd.min.js';

import Flickity from 'flickity';


/**
 * Slider
 * ------------------------------------------------ */

var flkty = new Flickity( '.wp-google-reviews', {
  // options
  cellAlign: 'left',
  contain: false,
  wrapAround: true,
  pageDots: true,
  prevNextButtons: true,
  autoPlay: true,
  autoPlay: 5000,
  adaptiveHeight: false
});


(function($) {

  AOS.init();

	/**
	 * Mobile nav toggle
	 * ------------------------------------------------ */

	var sidemenu_active = false;
	$('.site-nav-toggle').click(function(e) {
		e.preventDefault();
		sidemenu_active = true;
		$('body','html').addClass('overflow--noscroll');
		$('.site-nav-overlay').addClass('is-active');
		$('.site-nav-mobile').addClass('is-active');
	});
	$('.site-nav-overlay').bind('touchstart click', function(){
	  if (sidemenu_active) {
		$('body','html').removeClass('overflow--noscroll');
		$('.site-nav-overlay').removeClass('is-active');
		$('.site-nav-mobile').removeClass('is-active');
	    setTimeout(function(){
		    sidemenu_active = false;
		}, 100);
	  }
	  return false
	});


	$('.side-menu__nav li.menu-item-has-children').on('click', function(e) {
		if( e.target === this ) {
			e.preventDefault();
			$('> .sub-menu',this).slideToggle(100);
			$(this).toggleClass('is-active');
		}
	});


	/**
	 * Search
	 * ------------------------------------------------ */

	 var search_active = false;

	 $('.site-search').click(function(e) {
		e.preventDefault();
		search_active = true;
		$('.search-form').addClass('is-active');
		$('body','html').addClass('overflow--noscroll');
		$('.site-nav-overlay').addClass('is-active');
	});
	$('.site-nav-overlay').bind('touchstart click', function(){
	  if (search_active) {
		$('.search-form').removeClass('is-active');
		$('body','html').removeClass('overflow--noscroll');
		$('.site-nav-overlay').removeClass('is-active');
	    setTimeout(function(){
		    search_active = false;
		}, 100);
	  }
	  return false
	});






})( jQuery );
